<template>
  <div ref="scrollTarget"></div>
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
      <!-- Left Side Menu -->
      <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar">
        <div class="position-sticky" style="margin-top: 50px;">
          <ul class="nav flex-column" style="padding-left: 5%; margin-top: 10px;">
            <router-link to="/dashboard" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash1.png" class="img-item" style="margin-right: 12px;">
                {{
                  translate('dashboard')
                }}</li>
            </router-link>
            <router-link to="/orders-validate" style="color: #000;">
              <li class="nav-item custom active-item"><img src="../assets/img/wait_icon.svg" class="img-item"> {{ translate('shipments_to_validate') }} <span v-if="pending_orders_notification>0">({{ pending_orders_notification }})</span>
              </li>
            </router-link>
            <router-link to="/progress-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/progress_icon.svg" class="img-item"> {{ translate('shipment_progress') }} <span v-if="progress_orders_notification>0">({{ progress_orders_notification }})</span>
              </li>
            </router-link>
            <router-link to="/delivered-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/Check.svg" class="img-item"> {{
                translate('shipment_deleivred') }}
              </li>
            </router-link>
            <router-link to="/commissions" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash4.png" class="img-item" style="margin-right: 12px;">
                {{
                  translate('commissions') }}</li>
            </router-link>
            <router-link to="/new_order" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash5.png" class="img-item" style="margin-right: 12px;">
                {{
                  translate('new_shipment') }}</li>
            </router-link>
            <router-link v-if="company.bonus" to="/mybonus" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/bonus_icon.svg" class="img-item" style="margin-right: 12px;">
                {{translate('bonus') }} 
                <span style="margin-left: 5px; background-color: #E5097F; color: white; padding: 3px 8px 3px 8px; border-radius: 20px; font-weight: bold;">{{ company.bonus_points }} pts</span>
              </li>
            </router-link>
          </ul>

          <ul class="nav flex-column" style="margin-top: 400px; padding-left: 5%; margin-bottom: 10px;">
            <router-link to="/company" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash6.png" class="img-item"> {{ translate('my_company')
              }}</li>
            </router-link>
            <li class="nav-item custom" @click="logout()"><img src="../assets/img/dash8.png" class="img-item"> {{
              translate('sign_out') }}</li>
          </ul>
        </div>
      </nav>

      <!-- Content Area -->
      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" style=" background-color: #F7F7F8;">

        <div class="row justify-content-between" style="padding-right: 50px; padding-left: 50px; margin-top: 30px;">
          <div class="col-auto">
            <p style="font-weight: bold; color: black;">{{ translate('hello') }} {{ user.first_name }} <br> <span
                style="font-weight: 400;">
                {{ translate('welcome_back') }}
              </span></p>
          </div>
          <router-link to="/company" class="col-auto" style="cursor: pointer;">
            <p style="color: #E5097F; font-weight: bold;"> <i class="fa fa-building-o" aria-hidden="true"
                style="margin-right: 10px; font-size: 25px;"></i> {{ company.company_name }} </p>
          </router-link>
        </div>



        <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
          <!-- First Table -->
          <div class="col-12 text-center">
            <template v-if="shipments.length">
              <div class="table-responsive-lg">

                <table class="table table-hover table-striped table-bordered table-responsive">
                  <thead class="table-dark">
                    <tr>
                      <!-- Add the 'white-space: nowrap;' and 'text-align: center;' styles to table headers (th) -->
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-left-radius: 12px;">
                        {{ translate('tracking_number') }}</th>
                        <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('order_date') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('sender') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('ship_from') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('ship_to') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('payment') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-right-radius: 12px;">
                        {{ translate('status') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="shipment in shipments" :key="shipment.shipment_id">
                      <td
                        style="vertical-align: middle; text-align: center; color: #E5097F;"> {{ shipment.tracking_number }}</td>
                        <td style="vertical-align: middle; text-align: center; "> {{ shipment.order_date }}</td>
                        <td style="vertical-align: middle; text-align: center; "> {{ shipment.sender }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.ship_from }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.ship_to }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ translate(shipment.payment_type) }}</td>
                      <td
                        style="vertical-align: middle; text-align: center; color: #E5097F; cursor: pointer; text-decoration: underline;">
                        <button @click="selectShipment(shipment)" class="btn btn-warning" style="border-radius: 20px; padding-left: 7px; padding-right: 7px; padding-top: 0; padding-bottom: 0;"> 
                          {{ translate('confirm') }}
                        </button>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="container"
                style="text-align: center; color: white; font-weight: bold; font-size: 1.5em; background-color: #F1A8D0; padding: 60px; border-radius: 20px;">
                {{ translate('no_orders') }}
              </div>
            </template>

            <div v-if="selectedShipment" class="modal">
              <div class="modal-content">
                <span style="text-align: right;" @click="closeModal" class="close">&times;</span>

                <div class="row">
                  <div class="col-6" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                  <div>
                    <p style="color: black; font-weight: 600; font-size: 1.1em; text-align: left;">{{ translate('modal_title') }}</p>
                    <br>
                    <p style="color: black; font-weight: 400; text-align: left;">{{ translate('modal_title_desc') }}</p>
                  </div>
                  </div>
                  <div class="col-6" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <img style="height: 70%; width: 70%;" src="../assets/img/check_infos.svg">
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div style="background-color: #FDDDEF; border-radius: 20px; padding-top: 50px; padding-bottom: 50px; height: 100%;">
                    <p style="color: black; font-weight: 400;">
                      <img src="../assets/img/modal_track.svg"> {{ translate('tracking_number') }} : <b>{{ selectedShipment.tracking_number }}</b>
                    </p>
                    </div>
                  </div>
                  <div class="col-8">
                    <div style="background-color: #FDDDEF; border-radius: 20px; padding-top: 50px; padding-bottom: 50px; height: 100%;">
                      <img src="../assets/img/modal_rote.svg"> 
                    <div class="row">
                      <div class="col-6" style="text-align: center;">
                        <p style="color: black;">{{ translate('ship_from') }} : <b>{{ selectedShipment.ship_from }}</b></p>
                      </div>
                      <div class="col-6" style="text-align: center;">
                        <p style="color: black;">{{ translate('ship_to') }} : <b>{{ selectedShipment.ship_to }}</b></p>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-top: 20px;">
                  <div class="col-6" style="text-align: left;">
                    <div style="background-color: #FDDDEF; border-radius: 20px; padding-top: 50px; padding-bottom: 50px; height: 100%; padding-left: 35px;">
                    <p style="color: black; font-weight: 400;">
                      <img class="modal-img" src="../assets/img/modal_sender.svg"> {{ translate('sender') }} : <b>{{ selectedShipment.sender }}</b>
                    </p>
                    <br>
                    <p style="color: black; font-weight: 400;">
                      <img class="modal-img" src="../assets/img/modal_phone.svg"> {{ translate('phone') }} : <b>{{ selectedShipment.sender_phone }}</b>
                    </p>
                    <br>
                    <p style="color: black; font-weight: 400;">
                      <img class="modal-img" src="../assets/img/modal_payment.svg"> {{ translate('payment_type') }} : <b>{{ translate(selectedShipment.payment_type) }}</b>
                    </p>
                    <br>
                    <p style="color: black; font-weight: 400;">
                      <img class="modal-img" src="../assets/img/modal_price.svg"> {{ translate('price') }} : <span class="badge rounded-pill" style="font-size: 0.9em; padding: 0; padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px; background-color: #41D54B;">{{ selectedShipment.price }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span>
                    </p>
                    </div>
                  </div>
                  <div class="col-6" style="text-align: left;">
                    <div style="background-color: #FDDDEF; border-radius: 20px; padding-top: 50px; padding-bottom: 50px; height: 100%; padding-left: 35px;">
                    <p style="color: black; font-weight: 400;">
                      <img class="modal-img" src="../assets/img/modal_package.svg"> {{ translate('package_type') }} : <b>{{ selectedShipment.package_type }}</b>
                    </p>
                    <br>
                    <p style="color: black; font-weight: 400;">
                      <img class="modal-img" src="../assets/img/modal_desc.svg"> {{ translate('package_type') }} : <b>{{ selectedShipment.description }}</b>
                    </p>
                    <br>
                    <p style="color: black; font-weight: 400;">
                      <img class="modal-img" src="../assets/img/modal_weight.svg"> {{ translate('weight') }} : <b>{{ selectedShipment.weight }}</b>
                    </p>
                    <br>
                    <p style="color: black; font-weight: 400;"><img class="modal-img" src="../assets/img/modal_volume.svg"> {{ translate('le_volume') }} : </p>
                    <p style="color: black; font-weight: 400; text-align: center;"><b>
                      <span class="modal-span">{{ translate('length') }} : {{ length }}</span> 
                    <span class="modal-span">{{ translate('width') }} : {{ width }}</span> 
                  <span class="modal-span">{{ translate('height') }} : {{ height }}</span>
                    </b></p>
                    <br>
                    <p style="color: black; font-weight: 400;">
                      <img class="modal-img" src="../assets/img/modal_iconProd.svg"> <span @click="openProductsList" style="text-decoration: underline; cursor: pointer;">{{ translate('see_the_list') }}:</span>
                    </p>
                    <p style="text-align: center;"><img style="text-align: ce;" src="../assets/img/modal_proudtcs.svg"></p>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center" style="margin-top: 20px;">
                  <div class="col-6">
                    <div style="background-color: #FDDDEF; border-radius: 20px; padding-top: 50px; padding-bottom: 50px; height: 100%;">
                      <p style="color: black; font-weight: 600;">
                        {{ translate('call_us_questions') }}
                      </p>
                      <br>
                    <p>
                      <span class="modal-span-call"><img style="transform: scale(0.7);" src="../assets/img/modal_phone.svg"> 0032 2 333 37 77</span>
                    </p>
                    </div>
                  </div>
                </div>
                <div style="text-align: right;">
                  <router-link :to="'/edit-shipment/' + selectedShipment.shipment_id" class="btn btn-primary modify-btn">{{ translate('modify') }}</router-link>
                  <button class="btn btn-primary validate-btn" @click="goToValidation(selectedShipment.shipment_id)">{{ translate('validate') }}</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </main>
    </div>
  </div>
</template>
      
<script>
import axios from 'axios'
import Chart from 'chart.js/auto';
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';
import Swal from 'sweetalert2'

export default {
  name: 'Dashboard',
  mixins: [en, fr, nl],
  data() {
    return {
      user: {},
      today_shipments: "",
      progress_shipments: "",
      delivered_shipments: "",
      all_shipments: "",
      today_date: "",
      shipments_statistics: [],
      commissions_statistics: [],
      commission_amount: "",
      shipments: [],
      company: {},
      selectedShipment: null,
      pending_orders_notification : 0,
      progress_orders_notification : 0,
      length : "",
      width : "",
      height : ""
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
  },
  async created() {

    this.$store.commit('setIsLoading', true)
    await axios.get('/api/v1/users/me/')
      .then(async response => {
        this.user = response.data
        await this.getServicePoint(this.user.id)
      })
      .catch(error => {
        console.log(error)
      })
    this.$store.commit('setIsLoading', false)


    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    this.today_date = `${day}/${month}/${year}`;

  },
  watch: {
  selectedShipment(newValue, oldValue) {
    if(newValue === null) {
      return;
    }

    if(newValue.weight == 0.5){
      this.length = 32
      this.width = 24
      this.height = 1
    }
    if(newValue.weight == 1){
      this.length = 27
      this.width = 31
      this.height = 3
    }
    if(newValue.weight == 2){
      this.length = 27
      this.width = 31
      this.height = 4
    }
    if(newValue.weight == 5){
      this.length = 29
      this.width = 33
      this.height = 6
    }
    if(newValue.weight == 10){
      this.length = 40
      this.width = 31
      this.height = 25
    }
    if(newValue.weight == 20){
      this.length = 54
      this.width = 42
      this.height = 33
    }
  }
},
  async mounted() {
    document.title = 'Dashboard | Orders';
    const element = this.$refs.scrollTarget;
    element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);

    this.$store.commit('setIsLoading', true)
    await this.fetchShipments()
    await this.getNotifications()
    this.$store.commit('setIsLoading', false)

  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    async fetchShipments() {
      try {
        const response = await axios.post('/api/v1/get-orders-validate/');
        this.shipments = response.data['shipments'];
      } catch (error) {
        console.error('Error fetching shipments:', error);
      }
    },
    async getNotifications() {
      await axios.post('/api/v1/get-notifications/')
        .then(response => {
          this.pending_orders_notification = response.data['pending_orders']
          this.progress_orders_notification = response.data['progress_orders']
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    goToShipmentPage(shipmentId) {
      this.$router.push(`/track/${shipmentId}`);
    },
    async getServicePoint(user_id) {
      await axios.post('/api/v1/getservicepoint/')
        .then(response => {
          this.company = response.data
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    async printProformaInvoice(shipment_id) {
    const url = `https://demo.api.eurekali-point.com/api/v1/proformainvoice/print/${shipment_id}/`;
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = `proforma_invoice_${shipment_id}.pdf`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // For IE
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        }
    } catch (error) {
        console.error('Error downloading proforma invoice:', error);
    }
},
async printsaleticket(saleticket_id) {
    const url = `https://demo.api.eurekali-point.com/api/v1/saleticket/print/${saleticket_id}/`;
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = `sale_ticket_${saleticket_id}.pdf`;
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // For IE
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
        }
    } catch (error) {
        console.error('Error downloading sale ticket:', error);
    }
},
    goToValidation(shipmentId) {
      const currentShipment = this.shipments.find(sh => sh.shipment_id === shipmentId);

      if(currentShipment.ship_from == 'Morocco'){
        this.ValidateShipment(shipmentId)
      }
      if(currentShipment.ship_from == 'Belgium' || currentShipment.ship_to == 'Belgium'){
       this.$router.push(`/validate-shipment/${shipmentId}`);
      }
    },
    async ValidateShipment(shipment_id) {
      this.$store.commit('setIsLoading', true)
      await axios.post(`/api/v1/validate-shipment/${shipment_id}/`)
        .then(response => {
          toast({
            message: this.translate('shipment_validated'),
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })
          this.$router.push('/progress-orders')
        })
        .catch(error => {
          toast({
            message: "Something went wrong",
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: 'top-right',
          })
        })
      this.$store.commit('setIsLoading', false)
    },
    selectShipment(shipment) {
      this.selectedShipment = shipment;
    },
    closeModal() {
      this.selectedShipment = null;
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      sessionStorage.removeItem("token")
      this.$store.commit('removeToken')
      this.$store.commit('removeAgent')
      this.$store.commit('clearShipment')
      this.$router.push('/login')
    },
    openProductsList() {
            const urlToOpen = "https://eurekali-point.com/products";
            const newWindow = window.open(urlToOpen, '_blank');
            newWindow.focus();
        },
  }
}
</script>
      
<style scoped>
.custom {
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding-bottom: 10px;

}

.custom:hover {
  color: #E5097F;
  font-weight: bold;
}


.active-item {
  color: #E5097F;
  font-weight: bold;
}

.img-item {
  margin-right: 8px;
}

.red-header tr {
  background-color: red;
  color: white;
}

.btn-new-ship {
  color: #E5097F;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #E5097F;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.btn-new-ship:hover {
  background-color: #9b0656;
  color: white;
  border: 2px solid #9b0656;
}


.btn-more {
  color: #fff;
  font-weight: bold;
  background-color: #E5097F;
  border: 2px solid #E5097F;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btn-more:hover {
  background-color: #9b0656;
  border: 2px solid #9b0656;
}

.btn-request {
  background-color: #BCF2C0;
  margin-left: 12px;
  border-radius: 20px;
  border: 2px solid #37B980;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: bold;
}

.btn-request:hover {
  background-color: #37B980;
  color: white;
}


.box-shadow-custom {
  box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}


.bg-progress {
  background-color: #D5CB41;
}

.bg-delivered {
  background-color: #41D54B;
}

.validate-btn {
  padding: 5px;
  background-color: #E5097F;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #E5097F;
  font-weight: 600;
  user-select: none;
  border-radius: 20px;
}

.validate-btn:hover {
  background-color: #9b0656;
  border: none;
}

.modify-btn {
  padding: 5px;
  background-color: #FFF6FB;
  color: #E5097F;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #E5097F;
  user-select: none;
  font-weight: 600;
  border-radius: 20px;
  margin-right: 10px;
}

.modify-btn:hover {
  background-color: #E5097F;
  border: none;
  color: white;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 45%;
}

.modal-content {
  background-color: #FEEEF6;
  margin: 15% auto;
  padding: 20px;
  padding-left: 70px;
  padding-right: 70px;
  border: 1px solid #888;
  width: 65%;
  border-radius: 15px;
  margin-top: 100px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.modal-img{
  margin-right: 10px;
  transform: scale(0.95);
}
.modal-span{
  margin-right: 15px;
}
.modal-span-call{
  background-color: #FFFFFF; 
  color: #E5097F; 
  font-weight: 600; 
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
}
</style>