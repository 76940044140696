<template>
    <section style="margin-top: 30px; margin-bottom: 100px;">

        <div ref="scrollTarget"></div>
        <ProcessTimeLine />

        <div class="mobile-prices"
            style="width: 100%; background-color: #FEEEF6; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-top: -20px; margin-bottom: 30px;">
            <img src="../assets/img/sender_mobile_icon.svg"> <span
                style="color: #E5097F; margin-left: 5px; font-weight: 600;">{{
                    translate("sender_information") }}</span>
        </div>

        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-md-8 mb-4">
                    <div class="card mb-4" style="border-radius: 20px;">
                        <div class="card-header py-3"
                            style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                            <h5 class="mb-0" style="color: white; font-weight: bold;"> <img
                                    src="../assets/img/person_icon.svg"
                                    style="transform: scale(1.8); margin-right: 8px; margin-left: 5px;"> {{
                                        translate('sender_information') }}</h5>
                        </div>
                        <div class="card-body">
                            <form>
                                <!-- 2 column grid layout with text inputs for the first and last names -->
                                <div class="row mb-4">
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="text" class="form-control" :placeholder="translate('first_name')"
                                                v-model="user.first_name"
                                                :class="{ 'fill-out': user.first_name, 'not-fill-out': !user.first_name }">
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="text" class="form-control" :placeholder="translate('last_name')"
                                                v-model="user.last_name"
                                                :class="{ 'fill-out': user.last_name, 'not-fill-out': !user.last_name }">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="text" class="form-control" :placeholder="translate('company_name_field')"
                                                v-model="user.company_name"
                                                :class="{ 'fill-out': user.company_name, 'not-fill-out': !user.company_name }">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="email" class="form-control" :placeholder="translate('email')"
                                                v-model="user.email"
                                                :class="{ 'fill-out': user.email, 'not-fill-out': !user.email }">
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="tel" ref="phoneInput" class="form-control"
                                                placeholder="02 808 15 44" v-model="user.phone" maxlength="10"
                                                :class="{ 'fill-out': user.phone, 'not-fill-out': !user.phone }">
                                        </div>
                                    </div>
                                </div>

                                <div class="input-group mb-3 align-items-center">
                                    <input id="autocomplete" type="text" class="form-control"
                                        :placeholder="translate('search_address')">
                                </div>

                                <div class="row mb-4">
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="text" class="form-control" :placeholder="translate('number')"
                                                v-model="user.number"
                                                :class="{ 'fill-out': user.number, 'not-fill-out': !user.number }">
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="text" class="form-control" :placeholder="translate('street')"
                                                v-model="user.street"
                                                :class="{ 'fill-out': user.street, 'not-fill-out': !user.street }">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <template v-if="$store.state.shipment.express">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    :placeholder="translate('postal_code')"
                                                    v-model="$store.state.shipment.s_postal_code"
                                                    style="background-color: gainsboro;" readonly>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    :placeholder="translate('postal_code')" v-model="user.postal_code"
                                                    :class="{ 'fill-out': user.postal_code, 'not-fill-out': !user.postal_code }">
                                            </div>
                                        </div>
                                    </template>
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="text" class="form-control" :placeholder="translate('city')"
                                                v-model="user.city"
                                                :class="{ 'fill-out': user.city, 'not-fill-out': !user.city }">
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="text" class="form-control" :placeholder="translate('state')"
                                                v-model="user.state"
                                                :class="{ 'fill-out': user.state, 'not-fill-out': !user.state }">
                                        </div>
                                    </div>
                                    <div class="col-md">
                                        <div class="form-outline">
                                            <label></label>
                                            <input type="text" class="form-control" placeholder="Country"
                                                v-model="sender_country" style="background-color: gainsboro;" readonly>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>

                <div class="col-md-4 mb-4">
                    <div class="card mb-4" style="background-color: #FEEEF6; border-radius: 20px;border: none;">
                        <div class="card-header py-3"
                            style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                            <h5 class="mb-0" style="color: white;font-weight: bold;"><i class="fa fa-bars"
                                    aria-hidden="true"></i> {{ translate('summary') }}</h5>
                        </div>
                        <div class="card-body" style="background-color: #FEEEF6;">
                            <ul class="list-group list-group-flush" style="background-color: #FEEEF6;">
                                <template v-if="$store.state.shipment.country">
                                    <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                        <router-link to="/new_order" style="color: #E5097F;"><i class="fa fa-check"
                                                aria-hidden="true" style="margin-right: 5px;"></i> {{
                                                    translate('shipping_route_2') }} {{ new_country }})</router-link>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                        <router-link to="/new_order" class="link-secondary"> {{ translate('shipping_route')
                                        }}</router-link>
                                    </li>
                                </template>
                                <template v-if="$store.state.shipment.sender.first_name">
                                    <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                        <router-link to="/sender_information" style="color: #E5097F;"><i class="fa fa-check"
                                                aria-hidden="true" style="margin-right: 5px;"></i> {{
                                                    translate('sender_information') }} </router-link>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                        <router-link to="/sender_information" class="link-secondary"> {{
                                            translate('sender_information') }}</router-link>
                                    </li>
                                </template>

                                <li class="d-flex justify-content-between align-items-center border-0 px-0"
                                    style="margin-top: 2px;">
                                    <div>
                                        <strong style="color: #E5097F; font-weight: bold;">{{ translate('total_amount')
                                        }}</strong>
                                        <p class="mb-0" style="color: gray; font-weight: 600;">({{
                                            translate('including_vat') }} {{ VAT }}%)
                                        </p>
                                    </div>

                                    <template v-if="this.$store.state.shipment.origin_country === 'BE'">
                                        <span><strong style="color: #E5097F;">{{ price }} &euro;</strong></span>
                                </template>
                                <template v-else>
                                        <span><strong style="color: #E5097F;">{{ price }} MAD</strong></span>
                                    </template>
                                </li>

                            </ul>

                            <template v-if="user.number && user.street && user.city && user.state && user.country && user.email && user.phone && user.first_name && user.last_name && (user.postal_code || $store.state.shipment.s_postal_code)">
                                <button type="button" class="btn btn-next-active btn-lg btn-block" @click="checkPostalcode">
                                    {{ translate('next') }}
                                </button>
                            </template>
                            <template v-else>
                                <button type="button" class="btn btn-next-deactive btn-lg btn-block" @click="checkFields">
                                    {{ translate('next') }}
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
  
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import ProcessTimeLine from '@/components/ProcessTimeLine.vue';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'NewOrder',
    mixins: [en, fr, nl],
    components: {
        ProcessTimeLine
    },
    data() {
        return {
            new_country: "",
            price: "",
            VAT: "",
            package_type: "",
            number: "",
            street: "",
            postal_code: "",
            city: "",
            state: "",
            country: "",
            email: "",
            phone: this.phone = this.$store.state.shipment.sender.extention,
            first_name: "",
            last_name: "",
            company_name: "",
            weight: "",
            user: {},
            insure_my_parcel: "",
            assurance_amount: "",
            home_collection: "",
            home_collection_amount: "",

            sender_country: "",


        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    async created() {

        if (!this.$store.state.shipment.country || !this.$store.state.shipment.package_type || !this.$store.state.shipment.price) {
            this.$router.push('/new_order')
        }


    },
    async mounted() {
        document.title = 'Sender Information | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);

        this.new_country = this.$store.state.shipment.country
        this.price = this.$store.state.shipment.price
        this.VAT = this.$store.state.shipment.VAT
        this.package_type = this.$store.state.shipment.package_type
        this.weight = this.$store.state.shipment.weight

        this.insure_my_parcel = this.$store.state.shipment.insure_my_parcel
        this.assurance_amount = this.$store.state.shipment.assurance_amount
        this.home_collection = this.$store.state.shipment.home_collection
        this.home_collection_amount = this.$store.state.shipment.home_collection_amount


        //---------Country code phone
        const phoneInput = this.$refs.phoneInput;
        intlTelInput(phoneInput, {
            separateDialCode: true,
            preferredCountries: [this.$store.state.shipment.origin_country],
        });
        //------------------------


        if (!this.$store.state.shipment.sender.first_name) {
            await axios.get('/api/v1/users/me/')
                .then(response => {
                    this.user = response.data
                    if (this.user.is_agent) {
                        this.user = {}
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        } else {
            this.user = this.$store.state.shipment.sender
        }

        this.user.country = this.$store.state.shipment.origin_country === "BE" ? "Belgium" : "Morocco"

        var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
        autocomplete.setComponentRestrictions({ country: [this.$store.state.shipment.origin_country] });

        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            if (!place.address_components) {
                return;
            }

            let country = '';
            let postalCode = '';
            let state = '';
            let city = '';
            let number = '';
            let street = '';

            for (const component of place.address_components) {
                if (component.types.includes("country")) {
                    country = component.long_name;
                }
                if (component.types.includes("postal_code")) {
                    postalCode = component.long_name;
                }
                if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                }
                if (component.types.includes("locality")) {
                    city = component.long_name;
                }
                if (component.types.includes("street_number")) {
                    number = component.long_name;
                }
                if (component.types.includes("route")) {
                    street = component.long_name;
                }
            }

            this.user.country = country;
            this.user.postal_code = postalCode;
            this.user.state = state;
            this.user.city = city;
            this.user.number = number;
            this.user.street = street;
        });


        this.sender_country = this.$store.state.shipment.origin_country === "BE" ? "Belgium" : "Morocco"

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        checkFields() {
            if (!this.user.first_name) {
                toast({
                    message: this.translate('alert8'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.user.last_name) {
                toast({
                    message: this.translate('alert9'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.user.number) {
                toast({
                    message: this.translate('alert10'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.user.street) {
                toast({
                    message: this.translate('alert11'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.user.postal_code) {
                toast({
                    message: this.translate('alert12'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.user.city) {
                toast({
                    message: this.translate('alert13'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.user.state) {
                toast({
                    message: this.translate('alert14'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.user.email) {
                toast({
                    message: this.translate('alert15'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                });
                return;
            } else {
                // Email validation using regex
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(this.user.email)) {
                    toast({
                        message: this.translate('alert16'),
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-right',
                    });
                    return;
                }
            }

            if (!this.user.phone) {
                toast({
                    message: this.translate('alert17'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

        },
        async checkPostalcode() {
            if (!this.user.email) {
                toast({
                    message: this.translate('alert15'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                });
                return;
            } else {
                // Email validation using regex
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(this.user.email)) {
                    toast({
                        message: this.translate('alert16'),
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-right',
                    });
                    return;
                } else {

                    this.setSender()
                }
            }
        },
        setSender() {
            console.log('setSender')

            const number = this.user.number
            const street = this.user.street
            const postal_code = this.$store.state.shipment.express ? this.$store.state.shipment.s_postal_code : this.user.postal_code;
            const city = this.user.city
            const state = this.user.state
            const country = this.sender_country
            const email = this.user.email
            const first_name = this.user.first_name
            const last_name = this.user.last_name
            const company_name = this.user.company_name

            const phoneInput = this.$refs.phoneInput;
            const iti = intlTelInputGlobals.getInstance(phoneInput);
            const selectedCountryCode = "+" + iti.getSelectedCountryData().dialCode;


            const extention = selectedCountryCode
            const phone = this.user.phone

            this.$store.commit('setSender', {
                number: number,
                street: street,
                postal_code: postal_code,
                city: city,
                state: state,
                country: country,
                email: email,
                phone: phone,
                extention: extention,
                first_name: first_name,
                last_name: last_name,
                company_name: company_name
            })

            this.$router.push('/receiver_information')

        }
    }
}
</script>
  
  

<style>
.fill-out {
    background-color: #FEEEF6;
}

.not-fill-out {
    background-color: #F5F5F5;
}

.btn-next-active {
    width: 100%;
    margin-top: 20px;
    background-color: #E5097F;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-active:hover {
    background-color: #9b0656;
    color: white;
}

.btn-next-deactive {
    width: 100%;
    margin-top: 20px;
    background-color: gray;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-deactive:hover {
    background-color: gray;
    color: white;
}</style>