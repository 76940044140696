<template>
    <section style="margin-top: 30px; margin-bottom: 100px;">

        <div ref="scrollTarget"></div>
        <ProcessTimeLine2 />

        <div class="mobile-prices"
            style="width: 100%; background-color: #FEEEF6; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-top: -20px; margin-bottom: 30px;">
            <img src="../assets/img/sender_mobile_icon.svg"> <span
                style="color: #E5097F; margin-left: 5px; font-weight: 600;">{{
                    translate("sender_information") }}</span>
        </div>

        <div class="container mt-4">
            <div class="row justify-content-center">
                <template v-if="specialCountries.includes(this.$store.state.shipment.origin_country)">
                    <div class="col-md-8 mb-4">
                        <div class="card mb-4" style="border-radius: 20px;">
                            <div class="card-header py-3"
                                style="background-color: #9b0656; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                                <h5 class="mb-0" style="color: white; font-weight: bold;"><img
                                        src="../assets/img/person_icon.svg"
                                        style="transform: scale(1.8); margin-right: 8px; margin-left: 5px;"> {{
                                            translate('sender_information') }}</h5>
                            </div>
                            <div class="card-body">
                                <form>
                                    <!-- 2 column grid layout with text inputs for the first and last names -->
                                    <div class="row mb-4">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    :placeholder="translate('first_name')" v-model="first_name"
                                                    :class="{ 'fill-out': first_name, 'not-fill-out': !first_name }">
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    :placeholder="translate('last_name')" v-model="last_name"
                                                    :class="{ 'fill-out': last_name, 'not-fill-out': !last_name }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    :placeholder="translate('company_name_field')" v-model="company_name"
                                                    :class="{ 'fill-out': company_name, 'not-fill-out': !company_name }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="tel" ref="phoneInput" class="form-control"
                                                    placeholder="02 808 15 44" v-model="phone" maxlength="10"
                                                    :class="{ 'fill-out': phone, 'not-fill-out': !phone }">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="input-group mb-3 align-items-center">
                                        <input id="autocomplete" type="text" class="form-control"
                                            :placeholder="translate('search_address')">
                                    </div>


                                    <div class="row mb-4">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control" :placeholder="translate('city')"
                                                    v-model="city" :class="{ 'fill-out': city, 'not-fill-out': !city }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control" placeholder="Country"
                                                    v-model="new_country" style="background-color: gainsboro;" readonly>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <div class="col-md-8 mb-4">
                        <div class="card mb-4" style="border-radius: 20px;">
                            <div class="card-header py-3"
                                style="background-color: #9b0656; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                                <h5 class="mb-0" style="color: white; font-weight: bold;"><img
                                        src="../assets/img/person_icon.svg"
                                        style="transform: scale(1.8); margin-right: 8px; margin-left: 5px;"> {{
                                            translate('sender_information') }}</h5>
                            </div>
                            <div class="card-body">
                                <form>
                                    <!-- 2 column grid layout with text inputs for the first and last names -->
                                    <div class="row mb-4">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    :placeholder="translate('first_name')" v-model="first_name"
                                                    :class="{ 'fill-out': first_name, 'not-fill-out': !first_name }">
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    :placeholder="translate('last_name')" v-model="last_name"
                                                    :class="{ 'fill-out': last_name, 'not-fill-out': !last_name }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control"
                                                    :placeholder="translate('company_name')" v-model="company_name"
                                                    :class="{ 'fill-out': company_name, 'not-fill-out': !company_name }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="email" class="form-control" :placeholder="translate('email')"
                                                    v-model="email" :class="{ 'fill-out': email, 'not-fill-out': !email }">
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="tel" ref="phoneInput" class="form-control"
                                                    placeholder="02 808 15 44" v-model="phone" maxlength="10"
                                                    :class="{ 'fill-out': phone, 'not-fill-out': !phone }">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="input-group mb-3 align-items-center">
                                        <input id="autocomplete" type="text" class="form-control"
                                            :placeholder="translate('search_address')">
                                    </div>

                                    <div class="row mb-4">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" id="form7Example1" class="form-control"
                                                    :placeholder="translate('number')" v-model="number"
                                                    :class="{ 'fill-out': number, 'not-fill-out': !number }">
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control" :placeholder="translate('street')"
                                                    v-model="street"
                                                    :class="{ 'fill-out': street, 'not-fill-out': !street }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <template v-if="$store.state.shipment.express">
                                            <div class="col-md">
                                                <div class="form-outline">
                                                    <label></label>
                                                    <input type="text" class="form-control"
                                                        :placeholder="translate('postal_code')"
                                                        v-model="$store.state.shipment.r_postal_code"
                                                        style="background-color: gainsboro;" readonly>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-md">
                                                <div class="form-outline">
                                                    <label></label>
                                                    <input type="text" class="form-control"
                                                        :placeholder="translate('postal_code')" v-model="postal_code"
                                                        :class="{ 'fill-out': postal_code, 'not-fill-out': !postal_code }">
                                                </div>
                                            </div>
                                        </template>
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control" :placeholder="translate('city')"
                                                    v-model="city" :class="{ 'fill-out': city, 'not-fill-out': !city }">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control" :placeholder="translate('state')"
                                                    v-model="state" :class="{ 'fill-out': state, 'not-fill-out': !state }">
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div class="form-outline">
                                                <label></label>
                                                <input type="text" class="form-control" placeholder="Country"
                                                    v-model="new_country" style="background-color: gainsboro;" readonly>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </template>

                <div class="col-md-4 mb-4">
                    <div class="card mb-4" style="background-color: #FEEEF6; border-radius: 20px;border: none;">
                        <div class="card-header py-3"
                            style="background-color: #E5097F; border-top-right-radius: 20px; border-top-left-radius: 20px;">
                            <h5 class="mb-0" style="color: white;font-weight: bold;"> <i class="fa fa-bars"
                                    aria-hidden="true"></i> {{ translate('summary') }}</h5>
                        </div>
                        <div class="card-body">
                            <ul class="list-group list-group-flush">
                                <template v-if="$store.state.shipment.origin_country">
                                    <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                        <router-link to="/import/new_order" style="color: #E5097F;"><i class="fa fa-check"
                                                aria-hidden="true" style="margin-right: 5px;"></i> {{
                                                    translate('shipping_route_2') }} {{ new_country }})</router-link>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                        <router-link to="/import/new_order" class="link-secondary"> {{ translate('shipping_route')
                                        }}</router-link>
                                    </li>
                                </template>
                                <template v-if="$store.state.shipment.sender.first_name">
                                    <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                        <router-link to="/import/sender_information" style="color: #E5097F;"><i
                                                class="fa fa-check" aria-hidden="true" style="margin-right: 5px;"></i>
                                            {{ translate('sender_information') }}
                                        </router-link>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="d-flex  align-items-center border-0 px-0 pb-0">
                                        <router-link to="/import/sender_information" class="link-secondary"> {{
                                            translate('sender_information') }} </router-link>
                                    </li>
                                </template>

                                <li class="d-flex justify-content-between align-items-center border-0 px-0"
                                    style="margin-top: 2px;">
                                    <div>
                                        <strong style="color: #E5097F; font-weight: bold;">{{ translate('total_amount')
                                        }}</strong>
                                        <p class="mb-0" style="color: gray; font-weight: 600;">({{
                                            translate('including_vat') }} {{ VAT }}%)
                                        </p>
                                    </div>
                                    <span><strong style="color: #E5097F;">{{ price }} &euro;</strong></span>
                                </li>

                            </ul>

                            <template
                                v-if="number && street && city && state && phone && first_name && last_name && (postal_code || $store.state.shipment.r_postal_code)">
                                <button type="button" class="btn btn-next-active btn-lg btn-block" @click="checkPostalcode">
                                    {{ translate('next') }}
                                </button>
                            </template>
                            <template v-else>
                                <button type="button" class="btn btn-next-deactive btn-lg btn-block" @click="checkFields">
                                    {{ translate('next') }}
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
  
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import ProcessTimeLine2 from '@/components/ProcessTimeLine2.vue';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';

export default {
    name: 'NewOrder',
    mixins: [en, fr, nl],
    components: {
        ProcessTimeLine2
    },
    data() {
        return {
            new_country: "",
            price: "",
            package_type: "",
            weight: "",
            VAT: "",
            number: "",
            street: "",
            postal_code: "",
            city: "",
            state: "",
            email: "",
            phone: this.phone = this.$store.state.shipment.sender.extention,
            first_name: "",
            last_name: "",
            company_name: "",
            country_code: "",
            insure_my_parcel: "",
            assurance_amount: "",
            home_collection: "",
            home_collection_amount: "",

            sender_country: "",
            sender_postal_code: "",
            sender_city: "",

        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        discount() {
            return this.$store.state.shipment.discount;
        },

        specialCountries() {
            return [
                "Angola",
                "Antigua and Barbuda",
                "Aruba",
                "Ascension island",
                "Bahamas",
                "Belize",
                "Benin",
                "Botswana",
                "Bolivia",
                "Bonaire, Sint Eustatius and Saba",
                "Burkina Faso",
                "Burundi",
                "Cameroon",
                "Central African Republic",
                "Comoros",
                "Congo",
                "Congo the Democratic Republic of the",
                "Cook Islands",
                "Cote d’Ivoire",
                "Curaçao",
                "Djibouti",
                "Dominica",
                "East Timor",
                "Equatorial Guinea",
                "Eritrea",
                "Fiji",
                "French Southern Territories",
                "Gambia",
                "Gamon",
                "Ghana",
                "Grenada",
                "Guyana",
                "Heard and McDonald Islands",
                "Hong Kong",
                "Kiribati",
                "Libya",
                "Macau",
                "Malawi",
                "Mali",
                "Mauritania",
                "Nauru",
                "Netherlands Antilles",
                "Niue",
                "North Korea",
                "Qatar",
                "Rwanda",
                "Saint Kitts and Nevis",
                "Sao Tome and Principe",
                "Seychelles",
                "Sierra Leone",
                "Solomon Islands",
                "Suriname",
                "Syria",
                "Timor-Leste",
                "Togo",
                "Tokelau",
                "Tonga",
                "Tuvalu",
                "Uganda",
                "United Arab Emirates",
                "Vanuatu",
                "Yemen",
                "Zimbabwe"
            ];

        },
    },
    async created() {

    },
    async mounted() {
        document.title = 'Sender Information | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);

        await axios.get(`/api/v1/countrycode/${this.$store.state.shipment.origin_country}/`)
            .then(response => {
                this.country_code = response.data['country_code']

                //---------Country code phone
                const phoneInput = this.$refs.phoneInput;
                intlTelInput(phoneInput, {
                    separateDialCode: true,
                    preferredCountries: [this.country_code],
                });
                //------------------------

                var autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"),);
                autocomplete.setComponentRestrictions({ country: [this.country_code] });

                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();
                    if (!place.address_components) {
                        return;
                    }

                    let country = '';
                    let postalCode = '';
                    let state = '';
                    let city = '';
                    let number = '';
                    let street = '';

                    for (const component of place.address_components) {
                        if (component.types.includes("country")) {
                            country = component.long_name;
                        }
                        if (component.types.includes("postal_code")) {
                            postalCode = component.long_name;
                        }
                        if (component.types.includes("administrative_area_level_1")) {
                            state = component.long_name;
                        }
                        if (component.types.includes("locality")) {
                            city = component.long_name;
                        }
                        if (component.types.includes("street_number")) {
                            number = component.long_name;
                        }
                        if (component.types.includes("route")) {
                            street = component.long_name;
                        }
                    }



                    if (this.specialCountries.includes(this.$store.state.shipment.origin_country)) {
                        this.city = city;
                    } else {
                        this.postal_code = postalCode;
                        this.state = state;
                        this.city = city;
                        this.number = number;
                        this.street = street;
                    }
                });
            })
            .catch(error => {
                console.log(error)
            })

        this.new_country = this.$store.state.shipment.origin_country
        this.price = this.$store.state.shipment.price
        this.VAT = this.$store.state.shipment.VAT
        this.package_type = this.$store.state.shipment.package_type
        this.weight = this.$store.state.shipment.weight
        this.number = this.$store.state.shipment.sender.number
        this.street = this.$store.state.shipment.sender.street
        this.postal_code = this.$store.state.shipment.sender.postal_code
        this.city = this.$store.state.shipment.sender.city
        this.state = this.$store.state.shipment.sender.state
        this.email = this.$store.state.shipment.sender.email
        this.phone = this.$store.state.shipment.sender.phone
        this.first_name = this.$store.state.shipment.sender.first_name
        this.last_name = this.$store.state.shipment.sender.last_name
        this.company_name = this.$store.state.shipment.sender.company_name

        this.insure_my_parcel = this.$store.state.shipment.insure_my_parcel
        this.assurance_amount = this.$store.state.shipment.assurance_amount
        this.home_collection = this.$store.state.shipment.home_collection
        this.home_collection_amount = this.$store.state.shipment.home_collection_amount

        this.sender_country = this.$store.state.shipment.origin_country


        if (this.specialCountries.includes(this.$store.state.shipment.origin_country)) {
            this.email = "example@email.com"
            this.number = "-"
            this.street = "-"
            this.postal_code = "000"
            this.state = "-"
        }

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        checkFields() {
            if (!this.first_name) {
                toast({
                    message: this.translate('alert8'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.last_name) {
                toast({
                    message: this.translate('alert9'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.number) {
                toast({
                    message: this.translate('alert10'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.street) {
                toast({
                    message: this.translate('alert11'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.postal_code) {
                toast({
                    message: this.translate('alert12'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.city) {
                toast({
                    message: this.translate('alert13'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            if (!this.state) {
                toast({
                    message: this.translate('alert14'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

            /*if (!this.email) {
                toast({
                    message: this.translate('alert15'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                });
                return;
            } else {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(this.email)) {
                    toast({
                        message: this.translate('alert16'),
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'top-right',
                    });
                    return;
                }
            }*/

            if (!this.email) {
                this.email = "example@email.com"
            }

            if (!this.phone) {
                toast({
                    message: this.translate('alert17'),
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'top-right',
                })
                return
            }

        },
        async checkPostalcode() {
            if (!this.email) {
                this.email = "example@email.com"
            }
            this.setSender()
        },
        setSender() {
            console.log('setSender')

            const number = this.number
            const street = this.street
            const postal_code = this.$store.state.shipment.express ? this.$store.state.shipment.r_postal_code : this.postal_code;
            const city = this.city
            const state = this.state
            const country = this.sender_country
            const email = this.email
            const first_name = this.first_name
            const last_name = this.last_name
            const company_name = this.company_name

            const phoneInput = this.$refs.phoneInput;
            const iti = intlTelInputGlobals.getInstance(phoneInput);
            const selectedCountryCode = "+" + iti.getSelectedCountryData().dialCode;



            const extention = selectedCountryCode
            const phone = this.phone


            this.$store.commit('setSender', {
                number: number,
                street: street,
                postal_code: postal_code,
                city: city,
                state: state,
                country: country,
                email: email,
                phone: phone,
                extention: extention,
                first_name: first_name,
                last_name: last_name,
                company_name: company_name
            })

            this.$router.push('/import/receiver_information')


        }
    }
}
</script>
  
  
<style>
.fill-out {
    background-color: #FEEEF6;
}

.not-fill-out {
    background-color: #F5F5F5;
}

.btn-next-active {
    width: 100%;
    margin-top: 20px;
    background-color: #E5097F;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-active:hover {
    background-color: #9b0656;
    color: white;
}

.btn-next-deactive {
    width: 100%;
    margin-top: 20px;
    background-color: gray;
    color: white;
    font-weight: 600;
    padding: 2px;
    border-radius: 30px;
}

.btn-next-deactive:hover {
    background-color: gray;
    color: white;
}


.iti {

    display: block;
}</style>