<template>
    <div ref="scrollTarget"></div>

    <section style="margin-top: 100px; margin-bottom: 200px;">
        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <form class="p-4" @submit.prevent="submitForm"
                        style="border: 2px solid #E5097F; border-radius: 20px;">

                        <div class="form-group mb-3">
                            <input type="text" class="form-control" :placeholder="translate('tracking_number')" required
                                v-model="tracking_number">
                        </div>

                        <div class="text-center mt-3">
                            <button type="submit" class="btn btn-success btn-block mx-auto w-50"
                                style="background-color: #E5097F; border: 1px solid #E5097F; border-radius: 20px;"><i
                                    class="fa fa-map-marker" aria-hidden="true"></i> {{ translate('track')
                                }}</button><br />
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <template v-if="events.length && shipment">
            <div class="container" style="margin-top: 30px;">
                <div class="row"
                    style="text-align: center; box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa; border-radius: 5px;">
                    <div class="col-md-4 col-sm-12" style="margin-bottom: 80px;">
                        <template v-if="!shipment.status">
                            <p style="margin-top: 50px; margin-bottom: 15px; color: black;"><span
                                    style="font-weight: bold;  margin-right: 5px;"> {{ translate('status') }} : </span>
                                <span class="badge rounded-pill"
                                    :class="{ 'bg-progress': !shipment.status, 'bg-delivered': shipment.status }"
                                    style="font-size: 1em; back">{{ translate('in_progress') }} </span> <br>
                            </p>
                        </template>
                        <template v-else>
                            <p style="margin-top: 50px; margin-bottom: 15px; color: black;"><span
                                    style="font-weight: bold;  margin-right: 5px;"> {{ translate('status') }} : </span>
                                <span class="badge rounded-pill"
                                    :class="{ 'bg-progress': !shipment.status, 'bg-delivered': shipment.status }"
                                    style="font-size: 1em; back">{{ translate('delivered') }} </span> <br>
                            </p>
                        </template>


                        <p v-if="shipment.shipping_date" style="margin-bottom: 15px; color: black;"><span
                                style="font-weight: bold;  margin-right: 5px;">
                                {{ translate('shipping_date') }} : </span> {{ shipment.shipping_date }} <br></p>
                        <p v-if="shipment.delivery_date" style="margin-bottom: 15px; color: black;"><span
                                style="font-weight: bold;  margin-right: 5px;">
                                {{ translate('delivery_date') }} : </span> {{ shipment.delivery_date }} <br></p>


                        <p style="margin-top: 80px; color: #E5097F; font-weight: bold;">{{ translate('package_type') }}
                        </p> <br>
                        <img src="../assets/img/pack.svg">

                        <p style="margin-top: 10px; color: #E5097F; font-weight: bold;"> {{ shipment.package_type }}</p>
                        <p style="color: black; font-weight: 400;">{{ translate('max_weight') }} {{ shipment.weight }}
                            kg
                        </p> <br>


                    </div>
                    <div class="col-md-3 col-sm-12" style="background-color: #F5F5F5;">
                        <p style="margin-top: 50px; color: #E5097F; font-weight: bold;">{{ translate('service_point') }}
                            :
                        </p> <br>
                        <p style="color: black; padding-left: 10px; padding-right: 10px; font-weight: 600;">{{
                            shipment.service_point }}</p>
                        <p style="color: black; padding-left: 10px; padding-right: 10px;"><span
                                style="font-weight: 600;">
                                {{ translate('address') }} :</span> {{ shipment.service_point_address }}</p>
                        <p style="color: black; padding-left: 10px; padding-right: 10px;"><span
                                style="font-weight: 600;">
                                {{ translate('country') }} :</span> {{ shipment.service_point_country }}</p> <br>

                        <p style="margin-top: 50px; color: #E5097F; font-weight: bold;">{{ translate('sender') }} :</p>
                        <p style="color: black; padding-left: 10px; padding-right: 10px;"><span
                                style="font-weight: 600;">
                                {{ translate('address') }} :</span> {{ shipment.shipment_sender_address }}</p>
                        <p style="color: black; padding-left: 10px; padding-right: 10px;"><span
                                style="font-weight: 600;">
                                {{ translate('country') }} :</span> {{ shipment.shipment_sender_country }}</p> <br>


                        <p style="margin-top: 50px; color: #E5097F; font-weight: bold;">{{ translate('receiver') }} :
                        </p>
                        <p style="color: black; padding-left: 10px; padding-right: 10px;"><span
                                style="font-weight: 600;">
                                {{ translate('address') }} :</span> {{ shipment.shipment_recipient_address }}</p>
                        <p style="color: black; padding-left: 10px; padding-right: 10px;"><span
                                style="font-weight: 600; margin-bottom: 30px;"> {{ translate('country') }} :</span> {{
                                    shipment.shipment_recipient_country }}</p> <br>
                    </div>

                    <div class="col-md-5 col-sm-12">
                        <p style="margin-top: 50px; color: #E5097F; font-weight: bold;">{{ translate('shipping_history')
                            }}
                        </p> <br>

                        <div v-for="event in events" :key="event.id" class="row"
                            style="margin-top: 15px; margin-bottom: 30px; padding: 10px;">
                            <div class="col-2">
                                <img src="../assets/img/history.png">
                            </div>
                            <div class="col-10" style="text-align: left;">
                                <p style="color: black;"><span style="font-weight: bold;  margin-right: 5px;"> {{
                                    translate('date') }} : </span> {{ event.date }} {{ event.time }} </p>
                                <p style="color: black;"><span style="font-weight: bold;  margin-right: 5px;"> {{
                                    translate('status') }} : </span> {{ event.eventDescription }} </p>
                                <p style="color: black;"><span style="font-weight: bold;  margin-right: 5px;"> {{
                                    translate('location') }} : </span> {{ event.scanLocation }} </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import Swal from 'sweetalert2'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'LogIn',
    mixins: [en, fr, nl],
    data() {
        return {
            tracking_number: "",
            events: [],
            shipment: {},
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    mounted() {
        document.title = 'Tracking | Eurekali'
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });

        this.UpdateShipmentsStatus()

    },
    created() {
        const shipment_id = this.$route.params.shipment_id

        if (shipment_id) {
            this.tracking_number = shipment_id;
            this.submitForm()
        }
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        showAlertFailer() {
            Swal.fire({
                title: this.translate('track_title'),
                text: this.translate('track_txt'),
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                // Redirect to /new_order

            });
        },
        async submitForm() {
            this.$store.commit('setIsLoading', true)

            await this.getShipmentInformation()

            await axios.post(`/api/v1/track/${this.tracking_number}/`)
                .then(response => {
                    this.events = response.data['events']

                })
                .catch(error => {
                    console.log(error)
                    this.showAlertFailer()
                    this.events = []
                })
            this.$store.commit('setIsLoading', false)
        },
        async getShipmentInformation() {
            await axios.post(`/api/v1/get-shipment-information/${this.tracking_number}/`)
                .then(response => {
                    this.shipment = response.data['shipment']
                })
                .catch(error => {
                    console.log(error)

                })
        },
        async UpdateShipmentsStatus() {
            await axios.post('/api/v1/update-shipment-status/')
                .then(response => {
                    console.log("Shipments are updated")
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
    }
}
</script>


<style>
.bg-progress {
    background-color: #D5CB41;
}

.bg-delivered {
    background-color: #41D54B;
}
</style>