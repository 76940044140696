<template>
  <div ref="scrollTarget"></div>
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
      <!-- Left Side Menu -->
      <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block sidebar">
        <div class="position-sticky" style="margin-top: 50px;">
          <ul class="nav flex-column" style="padding-left: 5%; margin-top: 10px;">
            <router-link to="/dashboard" style="color: #000;">
              <li class="nav-item custom active-item"><img src="../assets/img/dash1.png" class="img-item"
                  style="margin-right: 12px;"> {{
                    translate('dashboard')
                  }}</li>
            </router-link>
            <router-link to="/orders-validate" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/wait_icon.svg" class="img-item"> {{
                translate('shipments_to_validate') }} <span v-if="pending_orders_notification > 0">({{
                  pending_orders_notification }})</span>
              </li>
            </router-link>
            <router-link to="/progress-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/progress_icon.svg" class="img-item"> {{
                translate('shipment_progress') }} <span v-if="progress_orders_notification > 0">({{
                  progress_orders_notification }})</span>
              </li>
            </router-link>
            <router-link to="/delivered-orders" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/Check.svg" class="img-item"> {{
                translate('shipment_deleivred') }}
              </li>
            </router-link>
            <router-link to="/commissions" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash4.png" class="img-item"
                  style="margin-right: 12px;">
                {{
                  translate('commissions') }}</li>
            </router-link>
            <router-link to="/new_order" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash5.png" class="img-item"
                  style="margin-right: 12px;">
                {{
                  translate('new_shipment') }}</li>
            </router-link>
            <router-link v-if="company.bonus" to="/mybonus" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/bonus_icon.svg" class="img-item"
                  style="margin-right: 12px;">
                {{ translate('bonus') }}
                <span
                  style="margin-left: 5px; background-color: #E5097F; color: white; padding: 3px 8px 3px 8px; border-radius: 20px; font-weight: bold;">{{
                    company.bonus_points }} pts</span>
              </li>
            </router-link>
          </ul>

          <ul class="nav flex-column" style="margin-top: 400px; padding-left: 5%; margin-bottom: 10px;">
            <router-link to="/company" style="color: #000;">
              <li class="nav-item custom"><img src="../assets/img/dash6.png" class="img-item"> {{
                translate('my_company')
                }}</li>
            </router-link>
            <li class="nav-item custom" @click="logout()"><img src="../assets/img/dash8.png" class="img-item"> {{
              translate('sign_out') }}</li>
          </ul>
        </div>
      </nav>

      <!-- Content Area -->
      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4" style=" background-color: #F7F7F8;">
        <div v-if="!company.is_active" class="inactive-account">
          <span>{{ translate('inactive_account') }}</span>
        </div>
        <div class="row justify-content-between" style="padding-right: 50px; padding-left: 50px; margin-top: 30px;">
          <div class="col-auto">
            <p style="font-weight: bold; color: black;">{{ translate('hello') }} {{ user.first_name }} {{ user.last_name
              }}
              <br> <span style="font-weight: 400;">
                {{ translate('welcome_back') }}
              </span>
            </p>
          </div>
          <router-link to="/company" class="col-auto" style="cursor: pointer;">
            <p style="color: #E5097F; font-weight: bold;"> <i class="fa fa-building-o" aria-hidden="true"
                style="margin-right: 10px; font-size: 25px;"></i> {{ company.company_name }} </p>
          </router-link>
        </div>

        <div class="row" style="margin-top: 30px;">
          <!-- First Table -->
          <router-link to="/orders-validate" class="col-12 col-md-4">
            <div class="row"
              style="width: 90%; border-radius: 15px;padding: 20px; background: linear-gradient(to right, #F4D4E5, #F4D4E5); color: black;">

              <div class="custom-background1">
                <div style="font-weight: bold; text-align: center;">{{ translate('shipments_to_validate') }}</div>
                <div
                  style="font-weight: bold; text-align: center; margin-top: 10px; margin-bottom: 10px; font-size: 40px;">
                  {{ notvalid_shipments }}
                </div>
                <div style="font-weight: 600; text-align: right; margin-top: 25px;">
                  {{ today_date }}
                </div>
              </div>
            </div>
          </router-link>

          <router-link to="/progress-orders" class="col-12 col-md-4">
            <div class="row justify-content-center"
              style="width: 90%; border-radius: 15px; padding: 20px;  background: linear-gradient(to right, #F5F2C8, #F5F2C8); color: black;">

              <div class="custom-background2">
                <div style="font-weight: bold; text-align: center;"> {{ translate('shipment_progress') }}</div>
                <div
                  style="font-weight: bold; text-align: center; margin-top: 10px; margin-bottom: 10px; font-size: 40px;">
                  {{ progress_shipments }}
                </div>
                <div style="font-weight: 600; text-align: right; margin-top: 25px;">
                  {{ today_date }}
                </div>
              </div>
            </div>
          </router-link>

          <router-link to="/delivered-orders" class="col-12 col-md-4">
            <div class="row justify-content-center"
              style="width: 90%; border-radius: 15px; padding: 20px;  background: linear-gradient(to right, #C4F8C8, #C4F8C8); color: black;">
              <div style="font-weight: bold; text-align: center;">{{ translate('shipment_deleivred') }}</div>

              <div class="custom-background3">
                <div
                  style="font-weight: bold; text-align: center; margin-top: 10px; margin-bottom: 10px; font-size: 40px;">
                  {{ delivered_shipments }}
                </div>
                <div style="font-weight: 600; text-align: right; margin-top: 25px;">
                  {{ today_date }}
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <div class="row" style="margin-top: 20px;">
          <div class="col-12">
            <div class="row border"
              style="width: 100%; border-radius: 15px;padding: 30px; background-color: #fff; margin-top: 10px; color: black;">

              <div class="col-6">
                <span style="color: #E5097F; font-weight: 600;">
                  <img src="../assets/img/outstandings.svg" style="transform: scale(0.8);"> {{
                    translate('my_outstandings') }}
                </span>

                <div style="background-color: #F4D4E5; padding: 5px; border-radius: 15px; margin-top: 20px;">
                  <div class="row" style=" padding-right: 15px; padding-left: 15px;">
                    <div class="col-6" style="font-weight: 600;">
                      {{ translate('shipments_amount') }}
                    </div>
                    <div class="col-6" style="font-weight: 600;">
                      <div class="col text-end">
                        {{ collections_amount }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span>
                      </div>
                    </div>
                  </div>

                  <div class="row" style=" padding-right: 15px; padding-left: 15px;">
                    <div class="col-6" style="font-weight: 600; margin-top: 5px;">
                      {{ translate('other_cash') }}
                    </div>
                    <div class="col-6" style="font-weight: 600; margin-top: 5px;">
                      <div class="col text-end">
                        {{ additionalCash_amount }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div style="background-color: #C4F8C8; padding: 5px; border-radius: 15px; margin-top: 20px;">
                  <div class="row" style=" padding-right: 15px; padding-left: 15px;">
                    <div class="col-6" style="font-weight: 600;">
                      {{ translate('commission_to_pay') }}
                    </div>
                    <div class="col-6" style="font-weight: 600;">
                      <div class="col text-end">
                        {{ commissions_amount }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-if="getStatseTotal">
                  <div style="background-color: #C4F8C8; padding: 5px; border-radius: 15px; margin-top: 20px;">
                    <div class="row" style=" padding-right: 15px; padding-left: 15px; color: black;">
                      <div class="col-6" style="font-weight: bold;">

                        <template v-if="getStatseTotal">
                          {{ translate('total_to_reimbursed') }}
                        </template>
                        <template v-else>
                          {{ translate('total_to_pay') }}
                        </template>
                      </div>
                      <div class="col-6" style="font-weight: bold; font-size: 18px;">
                        <div class="col text-end">
                          {{ formattedResult }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div style="background-color: #F4D4E5; padding: 5px; border-radius: 15px; margin-top: 20px;">
                    <div class="row" style=" padding-right: 15px; padding-left: 15px; color: black;">
                      <div class="col-6" style="font-weight: bold;">

                        <template v-if="getStatseTotal">
                          {{ translate('total_to_reimbursed') }}
                        </template>
                        <template v-else>
                          {{ translate('total_to_pay') }}
                        </template>
                      </div>
                      <div class="col-6" style="font-weight: bold; font-size: 18px;">
                        <div class="col text-end">
                          {{ parseFloat(formattedResult) * parseFloat(-1) }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-if="formattedResult != 0">
                  <div class="row" style="margin-top: 15px;">
                    <div class="col text-end">

                      <template v-if="getStatseTotal">
                        <button class="btn btn-refund" @click="RequestRefund"
                          style="padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;">{{
                            translate('request_refund')
                          }}</button>

                        <button class="btn btn-more-deactivate"
                          style="padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px;">{{
                            translate('invoice_request')
                          }}</button>

                      </template>

                      <template v-else>
                        <button class="btn btn-more-deactivate"
                          style="padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;">{{
                            translate('request_refund')
                          }}</button>

                        <button class="btn btn-more" @click="RequestInvoice"
                          style="padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px;">{{
                            translate('invoice_request')
                          }}</button>
                      </template>
                    </div>
                  </div>
                </template>
              </div>

              <div class="col-6">
                <div
                  style="width: 100%; text-align: justify; background-color: #F5F5F5; padding: 20px; border-radius: 15px; margin-top: 50px;">
                  <p>{{ translate('my_outstandings_text1') }} </p> <br>
                  <p> {{ translate('my_outstandings_text2') }} </p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div v-if="company.bonus" class="row" style="margin-top: 20px;">
          <div class="col-12">
            <div class="row border"
              style="width: 100%; border-radius: 15px;padding: 30px; background-color: #fff; margin-top: 10px; color: black;">
              <div class="col-5">
                <span style="color: #E5097F; font-weight: 600;">
                  <img src="../assets/img/gift_icon.svg" style=""> {{
                    translate('bonus') }}
                </span>

                <div class="row justify-content-center" style="margin-top: 30px;">
                  <div class="col-12 col-md-10">
                    <div class="row"
                      style="width: 100%; border-radius: 15px; padding: 20px; padding-bottom: 40px; background: linear-gradient(to right, #C4DFFF, #C4DFFF); color: black; height: 100%;">
                      <div class="custom-background4">
                        <div style="font-weight: bold; text-align: center;">{{
                          translate('my_points') }}</div>
                        <div
                          style="font-weight: bold; text-align: center; margin-top: 10px; margin-bottom: 10px; font-size: 3em;">
                          {{ company.bonus_points }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" style="margin-top: 15px;">
                  <div class="col text-center">
                    <router-link to="/mybonus" class="btn btn-bonus"
                      style="padding-left: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px; margin-left: 10px;">{{
                        translate('bonus_button') }}</router-link>
                  </div>
                </div>
              </div>

              <div class="col-7">
                <div
                  style="width: 100%; background-color: #F5F5F5; padding: 30px; border-radius: 15px; margin-top: 50px;">
                  <p style="font-weight: bold;">{{ translate('bonus_subtitle') }} </p> <br>
                  <ul class="list-unstyled">
                    <li><router-link class="highlight-span" to="/parrainage" style="background-color: #E5097F; color: white; cursor: pointer; padding-left: 7px; padding-right: 7px; border-radius: 5px;">&#8226; {{ translate('new_client_bonus') }} <b>{{ translate('new_client_points_bonus') }}</b></router-link></li>
                    <li>&#8226; {{ translate('edit_shipment_bonus') }} <b>{{ translate('edit_shipment_points_bonus')
                        }}</b></li>
                    <li>&#8226; {{ translate('new_shipment_bonus') }} <b>{{ translate('new_shipment_points_bonus')
                        }}</b></li>
                    <li>&#8226; {{ translate('fivepoints_bonus') }} <b>{{ translate('fivepoints_points_bonus') }}</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <template v-if="invoicesV2[0]">
          <div class="row" style="margin-top: 20px;">
            <!-- First Table -->
            <div class="col-12">
              <div class="row justify-content-center border"
                style="width: 100%; border-radius: 15px; padding: 30px;  background: #fff ">

                <div style="font-weight: 600; padding: 25px; color: #E5097F;">
                  <img src="../assets/img/my_bills.svg" style="transform: scale(0.9);"> {{ translate('my_bills') }}
                </div>
                <div>
                  <table class="table text-center" style="width: 100%;">
                    <thead>
                      <tr>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500; padding: 3px; text-align: center; border-top-left-radius: 20px; border-bottom-left-radius: 20px;">
                          {{ translate('invoice_number') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500; padding: 3px; text-align: center;">
                          {{ translate('invoice_date') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500;padding: 3px; text-align: center;">
                          {{ translate('amounts') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500;padding: 3px; text-align: center;">
                          {{ translate('status') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500;padding: 3px; text-align: center;">
                          {{ translate('details') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500;padding: 3px; text-align: center;">
                          {{ translate('invoice_in_pdf') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500;padding: 3px; text-align: center; border-top-right-radius: 20px; border-bottom-right-radius: 20px;">
                          {{ translate('payment') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="invoice in invoicesV2" :key="invoice.id">
                        <td style="vertical-align: middle; text-align: center; color: black;">{{ invoice.id }}</td>
                        <td style="vertical-align: middle; text-align: center; color: black;">{{ invoice.invoice_date }}
                        </td>
                        <td style="vertical-align: middle; text-align: center; color: black;">{{ invoice.total }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span>
                        </td>

                        <template v-if="invoice.is_paid === 'Yes'">
                          <td style="vertical-align: middle; text-align: center; color: black;"><span
                              style="background-color: #C4F8C8; border-radius: 20px; padding: 3px;">{{ translate('paid')
                              }}</span></td>
                        </template>
                        <template v-else>
                          <td style="vertical-align: middle; text-align: center; color: black;"><span
                              style="background-color: #F4D4E5; border-radius: 20px; padding: 3px;">{{
                                translate('not_paid') }}</span></td>
                        </template>

                        <td style="vertical-align: middle; text-align: center; color: black;">
                          <span @click="selectInvoice(invoice)"
                            style="cursor: pointer; color: #E5097F; text-decoration: underline;"> {{
                              translate('see_details') }}</span>
                        </td>

                        <template v-if="invoice.invoice_pdf === 'True'">
                          <td style="vertical-align: middle; text-align: center;"><i class="fa fa-print"
                              style="color: #E5097F; font-size: 20px; cursor: pointer;"
                              @click="printInvoiceV2(invoice.id)"></i></td>
                        </template>
                        <template v-else>
                          <td style="vertical-align: middle; text-align: center;"><i class="fa fa-print"
                              style="color: #aaa; font-size: 20px; cursor: not-allowed;"></i></td>
                        </template>

                        <template v-if="invoice.is_paid === 'Yes'">
                          <td style="vertical-align: middle; text-align: center; color: black;">
                            <button class="btn btn-pay-deactivate"> {{ translate('pay') }}</button>
                          </td>
                        </template>
                        <template v-else>
                          <td style="vertical-align: middle; text-align: center; color: black;">
                            <button class="btn btn-pay" @click="paymentAlert(invoice.id, invoice.total)"> {{
                              translate('pay')
                            }}</button>
                          </td>
                        </template>

                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="refunds[0]">
          <div class="row" style="margin-top: 20px;">
            <!-- First Table -->
            <div class="col-12">
              <div class="row justify-content-center border"
                style="width: 100%; border-radius: 15px; padding: 30px;  background: #fff ">

                <div style="font-weight: 600; padding: 25px; color: #E5097F;">
                  <img src="../assets/img/my_bills.svg" style="transform: scale(0.9);"> {{ translate('my_refunds') }}
                </div>
                <div>
                  <table class="table text-center" style="width: 100%;">
                    <thead>
                      <tr>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500; padding: 3px; text-align: center; border-top-left-radius: 20px; border-bottom-left-radius: 20px;">
                          {{ translate('invoice_number') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500; padding: 3px; text-align: center;">
                          {{ translate('date') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500;padding: 3px; text-align: center;">
                          {{ translate('amounts') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500;padding: 3px; text-align: center;">
                          {{ translate('details') }}</th>
                        <th
                          style="background-color: #F5F5F5; border: 2px #fff; font-weight: 500;padding: 3px; text-align: center; border-top-right-radius: 20px; border-bottom-right-radius: 20px;">
                          {{ translate('status') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="refund in refunds" :key="refund.id">
                        <td style="vertical-align: middle; text-align: center; color: black;">{{ refund.id }}</td>
                        <td style="vertical-align: middle; text-align: center; color: black;">{{ refund.refund_date }}
                        </td>
                        <td style="vertical-align: middle; text-align: center; color: black;">{{ refund.total }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span>
                        </td>

                        <td style="vertical-align: middle; text-align: center; color: black;">
                          <span @click="selectInvoice(refund)"
                            style="cursor: pointer; color: #E5097F; text-decoration: underline;"> {{
                              translate('see_details') }}</span>
                        </td>

                        <template v-if="refund.is_paid === 'Yes'">
                          <td style="vertical-align: middle; text-align: center; color: black;"><span
                              style="background-color: #C4F8C8; border-radius: 20px; padding: 3px;">{{ translate('paid')
                              }}</span></td>
                        </template>
                        <template v-else>
                          <td style="vertical-align: middle; text-align: center; color: black;"><span
                              style="background-color: #F4D4E5; border-radius: 20px; padding: 3px;">{{
                                translate('not_paid') }}</span></td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="row" style="margin-top: 20px; margin-bottom: 60px">
          <!-- First Table -->
          <div class="col-6">
            <div class="row border" style="width: 100%; border-radius: 15px;padding: 10px; background-color: #fff;">

              <p style="color: #41D54B; font-weight: bold;"><img src="../assets/img/chartcom.png"> {{
                translate('commissions') }}</p>
              <div>
                <apexchart type="area" :options="chartOptions2" :series="series2"></apexchart>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row border" style="width: 100%; border-radius: 15px; padding: 10px;  background: #fff ">

              <p style="color: #E5097F; font-weight: bold;"><img src="../assets/img/chartship.png"> {{
                translate('shipments') }}</p>
              <div>
                <apexchart type="area" :options="chartOptions1" :series="series1"></apexchart>
              </div>
            </div>
          </div>
        </div>


        <div v-if="selectedInvoice" class="modal">
          <div class="modal-content">
            <span style="text-align: right;" @click="closeModal" class="close">&times;</span>
            <div style="text-align: center;">
              <p style="color: #E5097F;"><strong style="color: #E5097F;">{{ translate('details') }}</strong> </p>
            </div>
            <div class="modal-body">
              <div class="column">
                <p><strong>{{ translate('shipments_amount') }} : </strong> <span class="badge bg-warning rounded-pill"
                    style="font-size: 0.9em;">{{ selectedInvoice.cash_payments }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span></p>
              </div>
              <div class="column">
                <p><strong>{{ translate('other_cash') }} : </strong> <span class="badge bg-warning rounded-pill"
                    style="font-size: 0.9em;">{{ selectedInvoice.additional_cash }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span></p>
              </div>
              <div class="column">
                <p><strong>{{ translate('commissions') }} : </strong> <span class="badge bg-success rounded-pill"
                    style="font-size: 0.9em;">{{ selectedInvoice.commissions }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span></p>
              </div>
            </div>
            <div style="text-align: center;">
              <p><strong>{{ translate('amounts') }} : </strong> <span class="badge bg-success rounded-pill"
                  style="font-size: 0.9em;">{{ selectedInvoice.total }} <span v-if="company.country === 'Belgium'">&euro;</span><span v-else>MAD</span></span></p>
            </div>
          </div>
        </div>


      </main>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import Swal from 'sweetalert2'
import VueApexCharts from 'vue3-apexcharts';
import { loadStripe } from '@stripe/stripe-js';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';

export default {
  name: 'Dashboard',
  mixins: [en, fr, nl],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      user: {},
      today_shipments: "",
      notvalid_shipments: "",
      progress_shipments: "",
      delivered_shipments: "",
      all_shipments: "",
      today_date: "",
      shipments_statistics: [],
      commissions_statistics: [],
      commission_amount: "",
      shipments: [],
      company: {},
      commissions_amount: "",
      collections_amount: "",
      additionalCash_amount: "",
      invoicesV2: [],
      refunds: [],
      errors: [],
      stripe: {},
      invoice_idddd: "",
      selectedInvoice: null,
      pending_orders_notification: 0,
      progress_orders_notification: 0,


      //data for charts 
      chartOptions1: {
        chart: {
          type: 'area',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#E5097F'],
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
        },
      },
      series1: [],


      chartOptions2: {
        chart: {
          type: 'area',
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: false,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false,
            },
          },
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#41D54B'],
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
        },
      },
      series2: [],

    }
  },
  async created() {

    this.$store.commit('setIsLoading', true)
    await axios.get('/api/v1/users/me/')
      .then(async response => {
        this.user = response.data

        if (!this.user.is_agent) {
          const toPath = this.$route.query.to || '/myorders'
          this.$router.push(toPath)
        }

        await this.getServicePoint(this.user.id)

      })
      .catch(error => {
        console.log(error)
      })
    


    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    this.today_date = `${day}/${month}/${year}`;

  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    result() {
      return parseFloat(this.commissions_amount) - parseFloat(this.collections_amount) - parseFloat(this.additionalCash_amount);
    },
    formattedResult() {
      return this.result.toFixed(2);
    },
    getResultColorClass() {
      return {
        'text-green': this.result > 0,
        'text-red': this.result < 0,
      };
    },
    getStatseTotal() {
      return this.result > 0
    },
  },
  async mounted() {
    document.title = 'Dashboard | Eurekali';
    const element = this.$refs.scrollTarget;
    element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);

    this.invoice_idddd = this.$route.params.invoice_id;
    const shipment_idd = this.$route.params.invoice_id;
    const payment_intent = this.$route.query.payment_intent;
    const payment_intent_client_secret = this.$route.query.payment_intent_client_secret;


    if (shipment_idd, payment_intent && payment_intent_client_secret) {
      this.checkPaymentStatus(shipment_idd, payment_intent, payment_intent_client_secret)
    }


    await axios.get('/api/v1/users/me/')
      .then(async response => {
        this.user = response.data
        if (!this.user.is_agent) {
          const toPath = this.$route.query.to || '/myorders'
          this.$router.push(toPath)
        } else {
          this.$store.commit('setIsLoading', true)
          await this.getStatistics();
          await this.getNotvalidShipments();
          await this.getShipmentsStatistics();
          await this.getCommissionsStatistics();
          await this.getCommission_amount();
          await this.getInvoicesV2();
          await this.getRefunds();
          await this.getAccounting();
          await this.getNotifications();
          this.UpdateShipmentsStatus();
          this.$store.commit('setIsLoading', false)
        }
      })
      .catch(error => {
        console.log(error)
      })




    this.series1 = [
      {
        name: this.translate('shipments'),
        data: this.shipments_statistics,
      },
    ];

    this.series2 = [
      {
        name: 'Commissions €',
        data: this.commissions_statistics,
      },
    ];



  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    async getStatistics() {
      await axios.post('/api/v1/getstatistics/')
        .then(response => {
          this.today_shipments = response.data['today_shipments']
          this.progress_shipments = response.data['progress_shipments']
          this.delivered_shipments = response.data['delivered_shipments']
          this.all_shipments = response.data['all_shipments']
        })
        .catch(error => {

          toast({
            message: this.translate('alert25'),
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'top-right',
          })
          console.log(JSON.stringify(error))
        })
    },
    async getNotvalidShipments() {
      await axios.post('/api/v1/get-shipments-notvalid/')
        .then(response => {
          this.notvalid_shipments = response.data['all_shipments']
        })
        .catch(error => {
          toast({
            message: this.translate('alert25'),
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'top-right',
          })
          console.log(JSON.stringify(error))
        })
    },
    async getShipmentsStatistics() {
      await axios.post('/api/v1/get-shipments-statistics/')
        .then(response => {
          this.shipments_statistics = response.data
        })
        .catch(error => {

          toast({
            message: this.translate('alert25'),
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'top-right',
          })
          console.log(JSON.stringify(error))
        })
    },
    async getCommissionsStatistics() {
      await axios.post('/api/v1/get-commissions-statistics/')
        .then(response => {
          this.commissions_statistics = response.data
        })
        .catch(error => {

          toast({
            message: this.translate('alert25'),
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'top-right',
          })
          console.log(JSON.stringify(error))
        })
    },
    async getCommission_amount() {
      await axios.post('/api/v1/get-commission-current/')
        .then(response => {
          this.commission_amount = response.data['commission_amount']
        })
        .catch(error => {

          toast({
            message: this.translate('alert25'),
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'top-right',
          })
          console.log(JSON.stringify(error))
        })
    },
    async getAccounting() {
      await axios.post('/api/v1/get-accounting/')
        .then(response => {
          this.commissions_amount = response.data['commissions_amount']
          this.collections_amount = response.data['collections_amount']
          this.additionalCash_amount = response.data['additionalCash_amount']
        })
        .catch(error => {

          toast({
            message: this.translate('alert25'),
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'top-right',
          })
          console.log(JSON.stringify(error))
        })
    },
    async getNotifications() {
      await axios.post('/api/v1/get-notifications/')
        .then(response => {
          this.pending_orders_notification = response.data['pending_orders']
          this.progress_orders_notification = response.data['progress_orders']
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    async UpdateShipmentsStatus() {
      await axios.post('/api/v1/update-shipment-status/')
        .then(response => {
          console.log("Shipments are updated")
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    async getServicePoint(user_id) {
      await axios.post('/api/v1/getservicepoint/')
        .then(response => {
          this.company = response.data
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    RequestInvoice() {
      Swal.fire({
        icon: 'question',
        title: this.translate('invoice_request'),
        text: this.translate('invoice_request_text'),
        showCancelButton: true,
        confirmButtonText: this.translate('Yes'),
        cancelButtonText: this.translate('No'),
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.CreateInvoiceV2()
        }
      });
    },
    async CreateInvoiceV2() {
      this.$store.commit('setIsLoading', true)
      try {
        await axios.post('/api/v1/create-invoicev2/')
          .then(async response => {
            this.getAccounting()
            await this.getInvoicesV2()

            toast({
              message: this.translate('invoicev2_created_success'),
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: 'top-right',
            })
          })

      } catch (error) {
        console.error(error);
      }

      this.$store.commit('setIsLoading', false);
    },
    async getInvoicesV2() {
      try {
        const response = await axios.post('/api/v1/get-invoicesv2/');
        this.invoicesV2 = response.data['invoicesv2'];
      } catch (error) {
        console.error('Error fetching invoicesv2:', error);
      }
    },
    RequestRefund() {
      Swal.fire({
        icon: 'question',
        title: this.translate('request_refund'),
        text: this.translate('request_refund_text'),
        showCancelButton: true,
        confirmButtonText: this.translate('Yes'),
        cancelButtonText: this.translate('No'),
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.CreateRefund()
        }
      });
    },
    async CreateRefund() {
      this.$store.commit('setIsLoading', true)
      try {
        await axios.post('/api/v1/create-refund/')
          .then(async response => {
            this.getAccounting()
            await this.getRefunds()

            toast({
              message: this.translate('refund_created_success'),
              type: 'is-success',
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: 'top-right',
            })
          })

      } catch (error) {
        console.error(error);
      }

      this.$store.commit('setIsLoading', false);
    },
    async getRefunds() {
      try {
        const response = await axios.post('/api/v1/get-refunds/');
        this.refunds = response.data['refunds'];
      } catch (error) {
        console.error('Error fetching refunds:', error);
      }
    },
    async printInvoiceV2(invoice_id) {
      const url = `https://demo.api.eurekali-point.com/api/v1/invoicev2/print/${invoice_id}/`;
      window.open(url, '_blank');
    },
    //payment
    async PayBanContact(invoice_id) {
      this.$store.commit('setIsLoading', true);
      try {
        await this.handlePaymentInvoice(invoice_id)

      } catch (error) {
        this.errors.push('Something went wrong. Please try again');
        console.error(error);
      }

      this.$store.commit('setIsLoading', false);
    },
    async handlePaymentInvoice(invoice_id) {
      const stripe = await loadStripe('pk_test_51MKIROJptvHeBaQ0WDtxbXehxX118MLWmDwbOuBFnJDisnOa10niECJb4KoUIGDidGSmrZqQzeWgyCrjcarAXvMJ00d8wQEleu');
      try {

        const response = await axios.post(`/api/v1/pay-invoicev2/bancontact/${invoice_id}/`);
        const responseData = response.data;
        if (responseData.error) {
          console.error('Error:', responseData.error);
        } else {
          try {
            const result = await stripe.confirmBancontactPayment(responseData.client_secret, {
              payment_method: {
                billing_details: {
                  name: this.user.first_name + " " + this.user.last_name,
                },
              },
              return_url: 'https://demo.eurekali-point.com/dashboard/' + invoice_id,
            });

            if (result.error) {
              console.error('Error:', result.error);
            } else if (result.paymentIntent.status === 'succeeded') {
              console.log('Payment successful!');
            }
          } catch (error) {
            console.error('Error:', error);
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    showAlertSuccess() {
      Swal.fire({
        title: this.translate('payment_success'),
        text: this.translate('payment_success_cong'),
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#4BB543',
      }).then(() => {
        // Redirect to /new_order
        this.$router.push('/dashboard');
      });
    },
    showAlertFailer() {
      Swal.fire({
        title: this.translate('payment_failed'),
        text: this.translate('payment_failed_txt'),
        icon: 'error',
        confirmButtonText: 'OK'
      }).then(() => {
        // Redirect to /new_order
        this.$router.push('/dashboard');
      });
    },
    async checkPaymentStatus(invoice_id, payment_intent_id, payment_intent_client_secret) {
      await axios.get(`/api/v1/checkpayment/invoicev2/bancontact/${invoice_id}/${payment_intent_id}/${payment_intent_client_secret}/`)
        .then(response => {
          console.log(response.data);

          if (response.data['status'] == true) {
            this.showAlertSuccess()
          } else {
            this.showAlertFailer()
          }

        })
        .catch(error => {
          console.error(error);
          this.showAlertFailer()
        });
    },
    paymentAlert(invoice_id, amount) {
      if (!this.insure_my_parcel) {
        Swal.fire({
          icon: 'warning',
          title: this.translate('invoice_payment'),
          text: this.translate('invoice_payment_text'),
          showCancelButton: true,
          confirmButtonText: this.translate('pay_with_card'),
          cancelButtonText: this.translate('pay_with_cash'),
          confirmButtonColor: '#007BFF',
          cancelButtonColor: '#E69D21',
        }).then((result) => {
          if (result.isConfirmed) {
            this.PayBanContact(invoice_id)
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.cashpayment(invoice_id, amount)
          }

        });
      } else {
        this.setProforma()
      }
    },
    async cashpayment(invoice_id, amount) {
      this.$store.commit('setIsLoading', true)
      const data = {
        'subject': "Invoice payment with cash - Agent : " + this.user.first_name + " " + this.user.last_name + " - Company : " + this.company.company_name,
        'message': "The agent is asking to pay the invoice " + invoice_id + " with cash,  the total is : " + amount + " euro"
      };
      try {
        await axios.post('/api/v1/send-email/', data)
          .then(response => {

            Swal.fire({
              title: this.translate('cash_alert'),
              text: this.translate('cash_alert_txt'),
              icon: 'success',
              confirmButtonText: 'OK',
              confirmButtonColor: '#4BB543',
            });

          })

      } catch (error) {
        console.error(error);
      }

      this.$store.commit('setIsLoading', false);
    },
    selectInvoice(invoice) {
      this.selectedInvoice = invoice;
    },
    closeModal() {
      this.selectedInvoice = null;
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      sessionStorage.removeItem("token")
      this.$store.commit('removeToken')
      this.$store.commit('removeAgent')
      this.$store.commit('clearShipment')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.custom {
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding-bottom: 10px;

}

.custom:hover {
  color: #E5097F;
  font-weight: bold;
}


.active-item {
  color: #E5097F;
  font-weight: bold;
}

.img-item {
  margin-right: 3px;
}

.red-header tr {
  background-color: red;
  color: white;
}

.btn-new-ship {
  color: #E5097F;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #E5097F;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.btn-new-ship:hover {
  background-color: #9b0656;
  color: white;
  border: 2px solid #9b0656;
}


.btn-more {
  color: #fff;
  font-weight: bold;
  background-color: #E5097F;
  border: 2px solid #E5097F;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btn-pay {
  color: #fff;
  font-weight: 500;
  background-color: #E5097F;
  border: 2px solid #E5097F;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.btn-refund {
  color: #fff;
  font-weight: bold;
  background-color: #64C96B;
  border: 2px solid #64C96B;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btn-bonus {
  color: #fff;
  font-weight: bold;
  background-color: #62A4F1;
  border: 2px solid #62A4F1;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btn-more-deactivate {
  color: #fff;
  font-weight: bold;
  background-color: #C4C4C4;
  border: 2px solid #C4C4C4;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  cursor: not-allowed;
}

.btn-pay-deactivate {
  color: #fff;
  font-weight: 500;
  background-color: #C4C4C4;
  border: 2px solid #C4C4C4;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  padding-top: 0px;
  cursor: not-allowed;
}

.btn-more:hover {
  background-color: #9b0656;
  border: 2px solid #9b0656;
}

.btn-pay:hover {
  background-color: #9b0656;
  border: 2px solid #9b0656;
}

.btn-refund:hover {
  background-color: #255c29;
  border: 2px solid #255c29;
}

.btn-bonus:hover {
  background-color: #C4DFFF;
  border: 2px solid #C4DFFF;
}

.btn-request {
  background-color: #BCF2C0;
  margin-left: 12px;
  border-radius: 20px;
  border: 2px solid #37B980;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: bold;
}

.btn-request:hover {
  background-color: #37B980;
  color: white;
}


.box-shadow-custom {
  box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}

.text-green {
  color: #37B980;
}

.text-red {
  color: red;
}

.custom-background1 {
  position: relative;
  background-image: url('../assets/img/shipments_panding.svg');
  background-size: 25%;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.custom-background2 {
  position: relative;
  background-image: url('../assets/img/shipments_progress.svg');
  background-size: 25%;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.custom-background3 {
  position: relative;
  background-image: url('../assets/img/shipments_delivred.svg');
  background-size: 25%;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.custom-background4 {
  position: relative;
  background-image: url('../assets/img/bonus_gift.svg');
  background-size: 15%;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 45%;
}

.modal-content {
  background-color: #FEEEF6;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.inactive-account {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 60px;
}

.inactive-account span {
  background-color: rgb(248, 100, 100);
  color: white;
  padding: 60px;
  font-size: 1.3em;
  border-radius: 20px;
}

.highlight-span{
    animation: pulsate 1s infinite;
}
</style>